import { ComponentEntity, Entity, ResourceEntity } from '@backstage/catalog-model';
import { components } from '@chanzuckerberg/argus-api';
import { ScorecardEntity } from './scorecard';

export const AWS_ACCOUNT_RESOURCE_TYPE = 'aws-account';

export type AWSAccountResourceEntity = ResourceEntity & {
  spec: {
    type: typeof AWS_ACCOUNT_RESOURCE_TYPE
    id: string
    name: string
    nameAlias?: string
    initiative: string
    team: string
    project: string
    company: string
    pocSupport?: string
    emailSupport?: string
    slackSupport?: string
    slackOncall?: string
  }
};

export function getAccountResourceID(accountID: string): string {
  return `${AWS_ACCOUNT_RESOURCE_TYPE}-${accountID}`;
}

export const EKS_CLUSTER_RESOURCE_TYPE = 'eks-cluster';

export type EKSClusterResourceEntity = ResourceEntity & ScorecardEntity & {
  spec: {
    type: typeof EKS_CLUSTER_RESOURCE_TYPE
    awsRegion: string
    version: string
    versionEOL: string
    endpoint: string
    tags: Record<string, string>
    argusCluster: components['schemas']['Cluster'] | undefined
  }
};

export function getClusterResourceID(clusterName: string, accountID: string): string {
  return `${EKS_CLUSTER_RESOURCE_TYPE}-${accountID}-${clusterName}`;
}

export const CZI_ANNOTATION_ARGUS_CLUSTER = 'czi.team/argus-cluster';
export const isArgusCluster = (entity: Entity): boolean => entity?.metadata.annotations?.[CZI_ANNOTATION_ARGUS_CLUSTER] === 'true';

export const ARGUS_APP_COMPONENT_TYPE = 'argus-app';
export type ArgusApp = components['schemas']['App'];
export type ArgusAppComponentEntity = ComponentEntity & {
  spec: {
    type: typeof ARGUS_APP_COMPONENT_TYPE
    app: ArgusApp
  }
};
export function getArgusAppComponentID(app: ArgusApp): string {
  return `${ARGUS_APP_COMPONENT_TYPE}-${app.name}`;
}
