import React from 'react';
import {
  InfoCard, StructuredMetadataTable,
} from '@backstage/core-components';
import {
  Alert, Grid,
} from '@mui/material';
import { useEntity } from '@backstage/plugin-catalog-react';
import { EKSClusterResourceEntity } from '@eng-portal/czi-extensions-common';
import { omit } from 'lodash';
import { CardTitle } from './CardTitle';

export function ArgusClusterDetailsCard(): JSX.Element {
  const { entity: cluster } = useEntity<EKSClusterResourceEntity>();
  if (!cluster) {
    return <Alert severity="error">Cluster not found</Alert>;
  }

  const { argusCluster } = cluster.spec;
  if (!argusCluster) {
    return <Alert severity="error">Argus cluster not found</Alert>;
  }

  const metadata: Record<string, any> = {
    ...omit(
      argusCluster,
      'id',
      'create_time',
      'update_time',
      'name',
      'api_endpoint',
      'certificate_authority_data',
    ),
  };

  return (
    <InfoCard
      title={<CardTitle title="Argus Cluster Details" />}
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item>
          <StructuredMetadataTable metadata={metadata} />
        </Grid>
      </Grid>
    </InfoCard>
  );
}
